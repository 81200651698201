<div class="row" style="position: relative; z-index: 10;">
  <!-- <div *ngIf="isBeforeJulyAnnounce" style="
    color: white;
    z-index: 1000;
    font-size: 2rem;
    width: 100%;
    text-align: center;
">Tickets for Aug to Oct shows go on sale JULY 1st at 6pm!</div> -->
  <div class="col-lg-12 col-xl-9">
    <div id="shitty-hero" class="lazy-bg" [style.opacity]="opacity" defaultImage="assets/images/default.png" [lazyLoad]="getImageUrl()" (onLoad)="fadeIn($event)"></div>
    <div id="shitty-logo">
      <img defaultImage="assets/images/default.png" lazyLoad="assets/images/theshittybarnsessions.png" class="d-block" alt="the shitty barn sessions" />
    </div>
    <div>
      <div *ngIf="!isBeforeSeasonAnnounce()" class="row">
        <div class="col-sm-12 col-md-6">
          <h2 class="h4">August -> October tickets go on sale July 1st!</h2>
          <p>Check out our 2024 Season Preview Playlist to help you decide what shows to grab tickets to:</p>

          <!-- <h2 class="h4"><a href="https://shittybarn24.itemorder.com/shop/home/" target="_blank">Merch here!</a></h2>
          <p>For a limited time, we are selling merch. Available to be ordered from our <a href="https://shittybarn24.itemorder.com/shop/home/" target="_blank">store</a>.</p>
          <p>The store is open until April 8th.</p> -->
        </div>
        <div class="col-sm-12 col-md-6">
          <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/4ccqvyZDXGoLMz4LVZuvDf?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>
      </div>
      <div *ngIf="isBeforeSeasonAnnounce()">
        Keep your eyes peeled for our 2024 season which will be announced in late March. Subscribe to our mailing list to be first to know!
      </div>
    </div>
    <app-upcoming-shows></app-upcoming-shows>
  </div>
  <div class="col-lg-12 col-xl-3 sidebar desktop-only">
    <app-sidebar fixed="true"></app-sidebar>
  </div>
</div>

